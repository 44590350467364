import React from 'react';
import styled from 'styled-components';
import {BrowserRouter, Switch, Route, NavLink, Redirect, useLocation, useHistory} from 'react-router-dom'

import {NonIdealState, Navbar, Alignment, Button} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';

import {CreateLinkPage} from './pages/create'
import {ListPage} from 'pages/list'
import {AuthPage} from 'pages/auth'
import {logout as logoutRequest} from "./api";
import {CreateUserPage} from "./pages/users/create";
import {UsersPage} from "./pages/users";
import {UsersCreationLogsPage} from "./pages/usersCreationLogs";


const prod = process.env.NODE_ENV === 'production'

const checkCookieIsExist = (cookiename) => {
  const d = new Date();
  d.setTime(d.getTime() + (1000));
  const expires = "expires=" + d.toUTCString();

  document.cookie = cookiename + "=new_value;path=/;" + expires;
  return document.cookie.indexOf(cookiename + '=') === -1;
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 95vh;
  padding-top: 60px;
  display: flex;
  flex-direction: column;

  .centered {
    margin: auto;
  }

  .navlink {
    color: #333;
    padding: 5px 7px;
    font-weight: bold;

    &:any-link {
      text-decoration: none;
    }

    &.active {
      border-bottom: 2px solid #0F9960;
    }
  }

`

const roles = {
  admin: 'admin',
  security: 'security',
  user: 'user'
}

const redirectsTo = {
  admin: '/?page=0',
  user: '/?page=0',
  security: '/usersCreationLogs',
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  return (
    <BrowserRouter basename={prod ? '/admin' : undefined}>
      <Router />
    </BrowserRouter>
  );
}

function Router() {
  const [isAuthorized, setIsAuthorized] = React.useState(checkCookieIsExist('refreshToken'))
  const query = useQuery();
  const history = useHistory();

  const role = localStorage.getItem('role')

  return (
    <Wrapper>
      {isAuthorized && (
        <Navbar fixedToTop>
          <Navbar.Group>
            <Navbar.Heading>lnk.olimp.bet</Navbar.Heading>
            {[roles.user, roles.admin].includes(role) && (
              <>
                <Navbar.Divider/>
                <NavLink to="/" exact className="navlink">Список ссылок</NavLink>
              </>
            )}
            {[roles.user, roles.admin].includes(role) && (
              <>
                <Navbar.Divider/>
                <NavLink to="/create" exact className="navlink">Создать ссылку</NavLink>
              </>
            )}
            {[roles.admin].includes(role) && (
              <>
                <Navbar.Divider/>
                <NavLink to="/users" exact className="navlink">Пользователи</NavLink>
              </>
            )}
            {[roles.security, roles.admin].includes(role) && (
              <>
                <Navbar.Divider/>
                <NavLink to="/usersCreationLogs" exact className="navlink">Журнал изменения пользователей</NavLink>
              </>
            )}
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <Button
              type='button'
              onClick={async () => {
                await logoutRequest()
                setIsAuthorized(false)
                localStorage.setItem('role', '')
              }}
            >
              Выйти
            </Button>
          </Navbar.Group>
        </Navbar>
      )}
      <Switch>
        <Route
          path="/login"
          exact
          render={() => {
            return (
              isAuthorized ?
                <Redirect to={redirectsTo[role]}/> :
                <AuthPage
                  setIsAuthorized={setIsAuthorized}
                />
            )
          }}
        />
        <Route
          path="/create"
          exact
          render={() => {
            return (
              !isAuthorized ?
                <Redirect to="/login"/> :
                <CreateLinkPage
                  setIsAuthorized={setIsAuthorized}
                />
            )
          }}
        />
        <Route
          path="/createUser"
          exact
          render={() => {
            return (
              !isAuthorized ?
                <Redirect to="/login"/> :
                <CreateUserPage
                  setIsAuthorized={setIsAuthorized}
                />
            )
          }}
        />
        <Route
          path="/users"
          exact
          render={() => {
            return (
              !isAuthorized ?
                <Redirect to="/login"/> :
                <UsersPage />
            )
          }}
        />
        <Route
          path="/usersCreationLogs"
          exact
          render={() => {
            return (
              !isAuthorized ?
                <Redirect to="/login"/> :
                <UsersCreationLogsPage />
            )
          }}
        />
        <Route
          path="/"
          exact
          render={() => {
            return (
              !isAuthorized ?
                <Redirect to="/login"/> :
                <ListPage
                  setIsAuthorized={setIsAuthorized}
                  page={Number(query.get('page'))}
                  setPage={(page) => history.push({
                    pathname: '/',
                    search: `?page=${page}${query.get('sort') ? '&sort=' + query.get('sort') : ''}`
                  })}
                  sort={query.get('sort') || '-_id'}
                  setSort={(sort) => history.push({
                    pathname: '/',
                    search: `?sort=${sort}${query.get('page') ? '&page=' + query.get('page') : ''}`
                  })}
                />
            )
          }}
        />
        <Route>
          <div className="centered">
            <NonIdealState
              title="404"
              icon={IconNames.CONSOLE}
              description={
                <>
                  Страница не найдена
                </>
              }
            />
          </div>
        </Route>
      </Switch>
    </Wrapper>
  )
}

export default App;
