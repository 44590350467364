import { useState, useCallback } from 'react'


export function useLegacyState(initialState = {}) {
  const [state, replaceState] = useState(initialState);

  const setState = useCallback((partialStateOrUpdater) => {
    if (typeof (partialStateOrUpdater) == 'function') {
      return replaceState((prevState) => {
        const partialState = partialStateOrUpdater(prevState);
        if (partialState != null && partialState !== prevState) {
          return { ...prevState, ...partialState };
        }
        return prevState;
      });
    }
    return replaceState(prevState => ({ ...prevState, ...partialStateOrUpdater }));
  }, [replaceState]);

  return [state, setState, replaceState];
}
