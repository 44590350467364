import { useState, useCallback, useEffect } from 'react'
import { unstable_batchedUpdates } from 'react-dom'

import { fetchUrlsList } from 'api'


const PAGE_SIZE = 25;

export const useCollectionPage = (dayStatistics, page, setPage, sort, setSort) => {
  const [rows, setRows] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const loadPage = useCallback(() => {
    setLoading(true);
    fetchUrlsList(page, sort, PAGE_SIZE, dayStatistics)
      .then((data) => {
        // Мне лень писать редьюсер ради одного конкретного кейса и типизировать его.
        // batchedUpdates собирает все апдейты и применяет их за один тик.
        unstable_batchedUpdates(() => {
          setLoading(false);
          setRows(data.list);
          setTotal(data.total);
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [page, sort, dayStatistics]);

  // fetch page
  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const changeSort = useCallback((nextSort) => {
    if (sort === nextSort) return;
    setSort(nextSort);
    setPage(0);
  }, [sort]);

  return {
    sort,
    setSort: changeSort,
    setRows,
    loadPage,
    loading,
    rows,
    total,
    pageSize: PAGE_SIZE,
  };
};
