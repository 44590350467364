import React, {useCallback, useState} from 'react'
import {
  Button,
  Intent,
  Callout,
  ButtonGroup,
  Code,
  Toaster,
  FormGroup,
  InputGroup,
  MenuItem
} from '@blueprintjs/core'
import {Select} from '@blueprintjs/select'

import {useLegacyState} from '../../lib/use-legacy-state'
import {createUser} from '../../api'
import qrcode from "qrcode";


const ToastFabric = Toaster.create()

const VALID_KEY_REGEXP = /^[0-9A-Za-z_-]+$/

const inputs = [
  {type: 'text', name: 'username', label: 'Логин'},
  {
    type: 'text',
    name: 'password',
    label: 'Пароль',
    validate: (value) => {
      if (!value) return;
      const valid = VALID_KEY_REGEXP.test(value)
      if (valid) return;
      return 'Ключ должен содержать только цифры, латинские буквы, а также знаки "-" и "_"'
    },
  },
  {
    type: 'select', name: 'role', label: 'Роль', options: [{
      label: 'Админ',
      value: 'admin'
    }, {
      label: 'Пользователь',
      value: 'user'
    }, {
      label: 'Служба безопасности',
      value: 'security',
    }]
  },
]

export const CreateUserPage = ({
  setIsAuthorized
}) => {
  const [form, setForm] = useLegacyState({})
  const [errors, setErrors] = useLegacyState({})
  const [status, setStatus] = useState({});
  const [qrCode, setQrCode] = useState();

  const handleChange = useCallback(({value, type, checked, name}) => {
    const state = type === 'checkbox' ? checked : value
    setForm({[name]: state})
    setErrors({[name]: undefined})
  }, [setForm, setErrors])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    let prevented = false
    const errors = inputs.reduce((acc, item) => {
      const value = form[item.name]
      if (item.required && !value) {
        acc[item.name] = 'Поле обязательно'
        prevented = true
      }
      if (item.validate) {
        const error = item.validate(value)
        acc[item.name] = error;
        if (error) {
          prevented = true
        }
      }
      return acc;
    }, {})

    setErrors(errors)
    if (prevented) return;
    setStatus({pending: true})
    createUser(form)
      .then(data => {
        qrcode.toDataURL(data.qrCode).then(setQrCode)
        setStatus({pending: false, data})
      })
      .catch(error => {
        setStatus({pending: false, error})
      })
  }, [form, setErrors])

  return (
    <form onSubmit={handleSubmit}>
      {status.error && (
        <Callout intent={Intent.DANGER} onClick={() => setStatus({})} title={status.error.message}/>
      )}
      {status.data && (
        <Callout intent={Intent.SUCCESS} title="Пользователь создан. Код от двухфакторной аутентификации:">
          <Code className="block">
            <img
              style={{marginLeft: 'auto', marginRight: 'auto'}}
              src={qrCode}
              title="TOTP URI QR code"
            />
          </Code>
          <ButtonGroup minimal>
            <Button onClick={() => setStatus({})}>Закрыть</Button>
          </ButtonGroup>
        </Callout>
      )}
      {inputs.map(({type, name, label, required, options, ...props}) => {
          switch (type) {
            case ('text'): {
              return (
                <FormGroup
                  label={label}
                  labelInfo={required && "(обязательное)"}
                  intent={errors[name] && Intent.DANGER}
                  helperText={errors[name]}
                >
                  <InputGroup
                    name={name}
                    {...props}
                    value={form[name] || ''}
                    onChange={(event) => handleChange(event.currentTarget)}
                    intent={errors[name] && Intent.DANGER}
                  />
                </FormGroup>
              )
            }
            case ('select'): {
              const lineItemRenderer = (
                category,
                {handleClick, modifiers}
              ) => {
                return (
                  <MenuItem
                    active={modifiers.active}
                    disabled={modifiers.disabled}
                    label={category.label}
                    key={category.id}
                    onClick={handleClick}
                    text={category.label}
                  />
                );
              };

              const selectedOption = options.find(e => e.value === form.role)

              return (
                <FormGroup
                  label={label}
                  labelInfo={required && "(обязательное)"}
                  intent={errors[name] && Intent.DANGER}
                  helperText={errors[name]}
                >
                  <Select
                    items={options}
                    itemRenderer={lineItemRenderer}
                    noResults={<MenuItem disabled={true} text="No results."/>}
                    onItemSelect={(item, event) => {
                      console.log('item', item)
                      console.log('event', event)
                      handleChange({
                        name,
                        type,
                        value: item.value
                      })
                    }}
                  >
                    <Button text={selectedOption ? selectedOption.label : 'Выберите роль'}
                            rightIcon="double-caret-vertical"/>
                  </Select>
                </FormGroup>
              )
            }
          }
        }
      )}
      <Button intent={Intent.SUCCESS} type="submit" disabled={status.pending}>Создать</Button>
    </form>
  )
}
