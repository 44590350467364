import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {
  HTMLTable,
  NonIdealState,
  ProgressBar,
  Intent,
  Icon,
  InputGroup,
  Dialog,
  Toaster,
  Button,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons';
import moment from 'moment';

import { useCollectionPage } from './use-collection-page'
import { Pagination } from './pagination'
import { useSearch } from 'pages/list/use-search'
import { EditForm } from 'components/edit-form'
import { copyFullUrl } from "../../lib/copy-to-clipboard";
import { DatePicker } from "./DatePicker";

const Td = styled.td`
  user-select: none;
  cursor: pointer;
`

const HeadCell = ({item, sort, children, setSort}) => {
  const desc = sort.startsWith('-')
  const sortKey = desc ? sort.slice(1): sort
  const active = sortKey === item
  const handleClick = () => {
    if (active) {
      setSort(`${desc ? '' : '-'}${item}`)
    }
    else {
      setSort(item)
    }
  }

  return (
    <Td onClick={handleClick} className={item}>
      {children}
      {active && <Icon icon={desc ? IconNames.SORT_DESC : IconNames.SORT_ASC} />}
    </Td>
  )
}

const Table = (props) => {
  const { rows, paged, sort, setSort, total, page, pageSize, setPage, onClickItem = () => null, openDataPicker, dayOfStatistics } = props;
  return (
    <>
      {rows.length > 0 ? (
        <HTMLTable bordered condensed striped interactive>
          <thead>
          {paged ? (
            <tr>
              <HeadCell item="key" sort={sort} setSort={setSort}>Ключ</HeadCell>
              <HeadCell item="url" sort={sort} setSort={setSort}>URL</HeadCell>
              <HeadCell item="created" sort={sort} setSort={setSort}>Дата создания</HeadCell>
              <HeadCell item="description" sort={sort} setSort={setSort}>Описание</HeadCell>
              <td style={{width: '1%'}}>Кол-во переходов</td>
              <td style={{width: '1%'}}>Кол-во уникальных переходов</td>
              <td style={{width: '15%'}}>
                Выбрать статистику за дату:
                <Button
                  icon={IconNames.CALENDAR}
                  style={{
                    marginLeft: '10px',
                  }}
                  title="Выбрать день"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    openDataPicker()
                  }}
                />
                {dayOfStatistics && (
                  <p>Статистика за день: {moment(dayOfStatistics).format('DD-MM-YYYY')}</p>
                )}
              </td>
            </tr>
          ) : (
            <tr>
              <td>Ключ</td>
              <td>URL</td>
              <td>Дата создания</td>
              <td>Описание</td>
              <td style={{width: '1%'}}>Кол-во переходов</td>
              <td style={{width: '1%'}}>Кол-во уникальных переходов</td>
              <td style={{width: '15%'}}>
                Выбрать статистику за дату:
                <Button
                  icon={IconNames.CALENDAR}
                  style={{
                    marginLeft: '10px',
                  }}
                  title="Выбрать день"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    openDataPicker()
                  }}
                />
                {dayOfStatistics && (
                  <p>Статистика за день: {dayOfStatistics}</p>
                )}
              </td>
            </tr>
          )}
          </thead>
          <tbody>
          {rows.map(item => (
            <tr key={item._id} onClick={() => onClickItem(item)}>
              <td>
                <Button
                  icon={IconNames.DUPLICATE}
                  title="Скопировать ссылку в буфер обмена"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    copyFullUrl(item.key)
                  }}
                />
                <span style={{ marginLeft: 10}}>{item.key}</span>
              </td>
              <td style={{maxWidth: '35vw', wordWrap: 'break-word'}} title={item.url}>{item.url}</td>
              <td>{item.created ? new Date(item.created).toLocaleString() : '-'}</td>
              <td>{item.description || '-'}</td>
              <td>{item.statisticAll || '-'}</td>
              <td>{item.statisticUnique || '-'}</td>
              <td>{item.statisticForDay || '-'}</td>
            </tr>
          ))}
          </tbody>
        </HTMLTable>
      ) : (
        <NonIdealState
          icon={IconNames.TH_DISCONNECT}
          title="Нет информации"
        />
      )}
      {paged && <Pagination total={total} currentPage={page} pageSize={pageSize} onChangePage={setPage}/>}
    </>
  )
}

const ToastFabric = Toaster.create()

export const ListPage = ({
  setIsAuthorized,
  page,
  setPage,
  sort,
  setSort,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [datePickerData, setDatePickerData] = useState(moment().format('YYYY-MM-DD'));
  const [searchData, setSearchData] = useState(null);

  const { loading, rows, total, pageSize, loadPage } = useCollectionPage(datePickerData, page, setPage, sort, setSort)
  const { handleSearch, searchResults } = useSearch(datePickerData);

  useEffect(() => {
    handleSearch(searchData)
  }, [searchData, datePickerData])

  const [editState, setEditState] = useState(null);

  if (loading) {
    return (
      <ProgressBar intent={Intent.PRIMARY}/>
    )
  }

  if (!rows) {
    return (
      <NonIdealState
        icon={IconNames.LIST}
        title="Нет информации"
      />
    )
  }
  const searchActive = searchResults != null

  return (
    <>
      <InputGroup
        leftIcon={IconNames.SEARCH}
        placeholder="Поиск по коллекции"
        onChange={(e) => {
          setSearchData(e.target.value)
        }}
      />
      <Table
        pageSize={pageSize}
        sort={sort}
        page={page}
        paged={!searchActive}
        rows={searchActive ? searchResults : rows}
        setPage={setPage}
        setSort={setSort}
        onClickItem={setEditState}
        total={total}
        openDataPicker={() => setOpenDatePicker(true)}
        dayOfStatistics={datePickerData}
      />
      <Dialog
        isOpen={editState != null}
        onClose={() => setEditState(null)}
        title="Изменить документ"
      >
        {editState && (
          <EditForm
            setIsAuthorized={setIsAuthorized}
            initialValues={editState}
            onClose={() => setEditState(null)}
            onSuccess={(data) => {
              if (data.short !== editState.key) {
                ToastFabric.show({
                  intent: Intent.SUCCESS,
                  message: `Создан новый ключ: ${data.short}`,
                })
              }
              else {
                ToastFabric.show({
                  intent: Intent.SUCCESS,
                  message: 'Документ успешно изменен',
                })
              }
              setEditState(null)
              loadPage()
            }}
          />
        )}
      </Dialog>
      {openDatePicker && (
        <DatePicker
          handleClose={() => setOpenDatePicker(false)}
          setDate={(day) => setDatePickerData(day)}
          date={datePickerData}
        />
      )}
    </>
  )
}
