import React, { useState, useCallback } from 'react'

import {Button, Callout, Classes, Intent, Toaster} from '@blueprintjs/core'

import {InputList} from 'components/input-list'
import {useLegacyState} from 'lib/use-legacy-state'
import {updateDocument} from 'api'

const ToastFabric = Toaster.create()

const VALID_KEY_REGEXP = /^[0-9A-Za-z_-]+$/

const inputs = [
  { name: 'url', label: 'Длинная ссылка', required: true, placeholder: 'https://www.olimp.bet/your/url/address' },
  {
    name: 'key',
    label: 'Ключ',
    required: true,
    validate: (value) => {
      const valid = VALID_KEY_REGEXP.test(value)
      if (valid) return;
      return 'Ключ должен содержать только цифры, латинские буквы, а также знаки "-" и "_"'
    },
  },
  { name: 'description', label: 'Описание' },
]

export const EditForm = ({ initialValues, onSuccess, onClose, setIsAuthorized }) => {
  const [status, setStatus] = useState({});
  const [form, setForm] = useLegacyState(initialValues)
  const [errors, setErrors] = useLegacyState({})

  const handleChange = useCallback((event) => {
    const { value, type, checked, name } = event.currentTarget;
    const state = type === 'checkbox' ? checked : value
    setForm({ [name]: state })
    setErrors({ [event.target.name]: undefined })
  }, [setForm, setErrors])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    let prevented = false
    if (status.pending) return;
    const errors = inputs.reduce((acc, item) => {
      const value = form[item.name]
      if (item.required && !value) {
        prevented = true
        acc[item.name] = 'Поле обязательно'
      }
      if (item.validate) {
        const error = item.validate(value)
        acc[item.name] = error;
        if (error) {
          prevented = true
        }
      }
      return acc;
    }, {})
    setErrors(errors)
    if (prevented) return;
    setStatus({ pending: true })
    updateDocument(form)
      .then(data => {
        onSuccess(data)
        setStatus({ pending: false, data })
      })
      .catch(error => {
        if (error.message.includes('Unauthorized')) {
          setIsAuthorized(false)
          ToastFabric.show({
            intent: Intent.DANGER,
            message: 'Время сессии вышло',
          })
        }
        setStatus({ pending: false, error })
      })
  }, [form, setErrors, status])
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <form onSubmit={handleSubmit}>
          {status.error && (
            <Callout intent={Intent.DANGER} onClick={() => setStatus({})} title={status.error.message}/>
          )}
          <InputList errors={errors} form={form} handleChange={handleChange} inputs={inputs} />
          <Button type="submit" style={{ display: 'none'}}>Изменить</Button>
        </form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button intent={Intent.SUCCESS} type="submit" disabled={status.pending} onClick={handleSubmit}>Изменить</Button>
        <Button intent={Intent.DANGER} disabled={status.pending} onClick={onClose}>Закрыть</Button>
      </div>
    </>
  )
}
