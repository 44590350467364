import React from 'react'

import {FormGroup, InputGroup, Intent} from '@blueprintjs/core'


export const InputList = ({ inputs, errors, handleChange, form }) => (
  <>
    {inputs.map(({ name, label, required, ...props}) => (
      <FormGroup
        label={label}
        labelInfo={required && "(обязательное)"}
        intent={errors[name] && Intent.DANGER}
        helperText={errors[name]}
      >
        <InputGroup
          name={name}
          {...props}
          value={form[name] || ''}
          onChange={handleChange}
          intent={errors[name] && Intent.DANGER}
        />
      </FormGroup>
    ))}
  </>
)
