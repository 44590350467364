export function toQueryString(data) {
  return Object.keys(data)
    .filter(key => data[key] != null && data[key] !== '')
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const getHeaders = (additional = {}) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  Object.entries(additional).forEach(([key, value]) => {
    headers.append(key, value)
  })
  return headers
}

const sendRequest = (url, options) => {
  return fetch(url, options)
    .then(async res => {
      if (res.ok) {
        return res.json()
      }
      const type = res.headers.get('Content-Type') || ''
      if (type.includes('application/json')) {
        return res.json().then(data => {
          throw data
        })
      }
      throw new Error(await res.text())
    })
}

const sendRequestDeprecated = (url, options) => {
  return fetch(url, options)
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      const type = res.headers.get('Content-Type') || ''
      if (type.includes('application/json')) {
        return res.json().then(data => {
          throw data
        })
      }
      throw new Error(`Произошла ошибка ${res.statusText}`)
    })
}


export const createShortUrl = (params) => {
  return sendRequestDeprecated('/api/short', { method: "POST", body: JSON.stringify(params), headers: getHeaders() })
}
export const updateDocument = (params) => {
  const short = params.short || params.key;
  const full = params.full || params.url;
  return sendRequestDeprecated('/api/short', { method: "PUT", body: JSON.stringify(params), headers: getHeaders() })

}
export const fetchUrlsByQuery = (query, dayStatistic) => {
  const qs = toQueryString({ q: query, dayStatistic })
  const url = `/api/find?${qs}`
  return sendRequestDeprecated(url, { headers: getHeaders() })
}

export const fetchUrlsList = (page, sort, size, dayStatistic) => {
  const qs = toQueryString({ page, sort, size, dayStatistic })
  return sendRequestDeprecated(`/api/list?${qs}`)
}

export const login = (data) => {
  return sendRequestDeprecated('/admin/login', { method: "POST", body: JSON.stringify(data), headers: getHeaders() })
}

export const logout = () => {
  return sendRequestDeprecated('/admin/logout', { method: "POST", headers: getHeaders() })
}

export const fetchUsersCreationLogs = () => {
  return sendRequestDeprecated('/api/users-creation-logs')
}

export const fetchUsers = () => {
  return sendRequestDeprecated('/api/users')
}

export const createUser = (params) => {
  return sendRequest('/api/users', { method: "POST", body: JSON.stringify(params), headers: getHeaders() })
}

export const deleteUser = (id) => {
  return sendRequestDeprecated(`/api/users/${id}`, { method: "DELETE", headers: getHeaders() })
}
