import { useState, useRef, useCallback } from 'react'
import {fetchUrlsByQuery} from 'api'


export const useSearch = (dayStatistic) => {
  const [searchResults, setSearchResults] = useState(null)
  const lastQuery = useRef('')

  const clearResults = useCallback(() => {
    setSearchResults(null)
    lastQuery.current = ''
  }, [setSearchResults, lastQuery])

  const handleSearch = useCallback((text) => {
    lastQuery.current = text

    if (text && text.length >= 2) {
      fetchUrlsByQuery(text, dayStatistic)
        .then((res) => {
          if (lastQuery.current === text) {
            setSearchResults(res)
          }
        })
        .catch(() => {
          setSearchResults([])
        })
    }
    else {
      setSearchResults(null)
    }
  }, [setSearchResults, dayStatistic])


  return { clearResults, handleSearch, searchResults }
}
