import React, {useEffect, useState} from 'react'
import {
  HTMLTable,
  NonIdealState,
  ProgressBar,
  Intent,
  Dialog,
  Button,
  Classes,
  Callout,
} from '@blueprintjs/core'
import {IconNames} from '@blueprintjs/icons';

import {fetchUsers as fetchUsersRequest, deleteUser as deleteUserRequest} from "../../api";
import {useHistory} from "react-router-dom";

const Table = (props) => {
  const {rows, setDeleteUser} = props;
  return (
    <>
      {rows.length > 0 ? (
        <HTMLTable bordered condensed striped interactive>
          <thead>
          <tr>
            <td>Логин</td>
            <td>Роль</td>
            <td>Дата создания</td>
            <td>Удалить</td>
          </tr>
          </thead>
          <tbody>
          {rows.map(item => (
            <tr key={item._id}>
              <td>{item.username}</td>
              <td>{item.role}</td>
              <td>{item.created ? new Date(item.created).toLocaleString() : '-'}</td>
              <td>
                <Button
                  icon={IconNames.DELETE}
                  title="Удалить"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setDeleteUser(item)
                  }}
                />
                <span style={{marginLeft: 10}}>{item.key}</span>
              </td>
            </tr>
          ))}
          </tbody>
        </HTMLTable>
      ) : (
        <NonIdealState
          icon={IconNames.TH_DISCONNECT}
          title="Нет информации"
        />
      )}
    </>
  )
}

export const UsersPage = () => {
  const history = useHistory();

  const [deleteUser, setDeleteUser] = useState(null);
  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState({})

  const fetchUsers = () => {
    setLoading(true)
    fetchUsersRequest()
      .then((data) => {
        setUsersList(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
  useEffect(fetchUsers, []);

  if (loading) {
    return (
      <ProgressBar intent={Intent.PRIMARY}/>
    )
  }

  // if (!usersList.length) {
  //   return (
  //     <NonIdealState
  //       icon={IconNames.LIST}
  //       title="Нет информации"
  //     />
  //   )
  // }

  const handleDeleteUser = () => {
    deleteUserRequest(deleteUser.id).then(res => {
      setDeleteUser(null)
      setStatus({
        success: 'Пользователь удален'
      })
      fetchUsers()
    }).catch(err => {
      setStatus({
        error: 'Не удалось удалить пользователя'
      })
      console.error(err);
    })
  }

  return (
    <>
      {status.error && (
        <Callout intent={Intent.DANGER} onClick={() => setStatus({})} title={status.error}/>
      )}
      {status.success && (
        <Callout intent={Intent.SUCCESS} title={status.success} />
      )}
      <Button intent="success" icon={IconNames.ADD} onClick={() => {
        history.push('/createUser')
      }} style={{
        width: '15%'
      }}>Создать пользователя</Button>
      <Table
        rows={usersList}
        setDeleteUser={setDeleteUser}
      />
      {deleteUser && (
        <Dialog
          isOpen={true}
          onClose={() => setDeleteUser(null)}
          title={`Удалить пользователя ${deleteUser.username}?`}
        >
          <div className={Classes.DIALOG_BODY}>
            <div>
              <Button intent={Intent.DANGER} onClick={handleDeleteUser} style={{ margin: "" }}>
                Удалить
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}
