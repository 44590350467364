import React, { useCallback, useState } from 'react'
import { Button, Intent, Callout, ButtonGroup, Code, Checkbox, Toaster} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { useLegacyState } from 'lib/use-legacy-state'
import { createShortUrl } from 'api'
import {InputList} from 'components/input-list'

const ToastFabric = Toaster.create()

const VALID_KEY_REGEXP = /^[0-9A-Za-z_-]+$/

const inputs = [
  { name: 'full', label: 'Длинная ссылка', required: true, placeholder: 'https://www.olimp.bet/your/url/address' },
  {
    name: 'short',
    label: 'Ключ',
    validate: (value) => {
      if (!value) return;
      const valid = VALID_KEY_REGEXP.test(value)
      if (valid) return;
      return 'Ключ должен содержать только цифры, латинские буквы, а также знаки "-" и "_"'
    },
    placeholder: 'Генерируется автоматически, если не задан'
  },
  { name: 'description', label: 'Описание' },
]

export const CreateLinkPage = ({
  setIsAuthorized
}) => {
  const [form, setForm] = useLegacyState({})
  const [errors, setErrors] = useLegacyState({})
  const [status, setStatus] = useState({});

  const handleChange = useCallback((event) => {
    const { value, type, checked, name } = event.currentTarget;
    const state = type === 'checkbox' ? checked : value
    setForm({ [name]: state })
    setErrors({ [event.target.name]: undefined })
  }, [setForm, setErrors])

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    let prevented = false
    const errors = inputs.reduce((acc, item) => {
      const value = form[item.name]
      if (item.required && !value) {
        acc[item.name] = 'Поле обязательно'
        prevented = true
      }
      if (item.validate) {
        const error = item.validate(value)
        acc[item.name] = error;
        if (error) {
          prevented = true
        }
      }
      return acc;
    }, {})
    setErrors(errors)
    if (prevented) return;
    setStatus({ pending: true })
    createShortUrl(form)
      .then(data => {
        setStatus({ pending: false, data })
      })
      .catch(error => {
        if (error.message.includes('Unauthorized')) {
          setIsAuthorized(false)
          ToastFabric.show({
            intent: Intent.DANGER,
            message: 'Время сессии вышло',
          })
        }
        setStatus({ pending: false, error })
      })
  }, [form, setErrors])

  return (
    <form onSubmit={handleSubmit}>
      {status.error && (
        <Callout intent={Intent.DANGER} onClick={() => setStatus({})} title={status.error.message}/>
      )}
      {status.data && (
        <Callout intent={Intent.SUCCESS} title="Ссылка создана">
          <Code className="block">{getShortLink(status.data)}</Code>
          <ButtonGroup minimal>
            <Button icon={IconNames.DUPLICATE} onClick={() => copy(getShortLink(status.data))}>Скопировать</Button>
            <Button onClick={() => setStatus({})}>Закрыть</Button>
          </ButtonGroup>
        </Callout>
      )}
      <InputList errors={errors} form={form} handleChange={handleChange} inputs={inputs} />
      <Checkbox
        checked={form.returnExisting}
        label="Возвращать старый ключ, если длинная ссылка уже есть в базе"
        onChange={handleChange}
        name="returnExisting"
      />
      <Button intent={Intent.SUCCESS} type="submit" disabled={status.pending}>Сгенерировать</Button>
    </form>
  )
}

const copy = (text) => {
  const textarea = document.createElement('textarea')
  textarea.value = text;
  // textarea.style.visibility = 'hidden'
  document.body.appendChild(textarea)
  textarea.select()
  // eslint-disable-next-line
  const result = document.execCommand('copy')
  document.body.removeChild(textarea)
}

const getShortLink = (data) => {
  if (!data) return '';
  return `${window.location.origin}/${data.short}`
}
