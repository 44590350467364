import React, {useEffect, useState} from 'react'
import {
  HTMLTable,
  NonIdealState,
  ProgressBar,
  Intent,
} from '@blueprintjs/core'
import {IconNames} from '@blueprintjs/icons';
import {fetchUsersCreationLogs } from "../../api";

const ACTION_MAP = {
  create: 'создал',
  delete: 'удалил'
}

const Table = (props) => {
  const {rows} = props;
  return (
    <>
      {rows.length > 0 ? (
        <HTMLTable bordered condensed striped interactive>
          <thead>
          <tr>
            <td>Пользователь</td>
            <td>Действие</td>
            <td>Субъект</td>
            <td>Дата</td>
          </tr>
          </thead>
          <tbody>
          {rows.map(item => (
            <tr key={item._id}>
              <td>{item.username}</td>
              <td>{ACTION_MAP[item.action]}</td>
              <td>{item.subjectUsername}</td>
              <td>{item.createdAt ? new Date(item.createdAt).toLocaleString() : '-'}</td>
            </tr>
          ))}
          </tbody>
        </HTMLTable>
      ) : (
        <NonIdealState
          icon={IconNames.TH_DISCONNECT}
          title="Нет информации"
        />
      )}
    </>
  )
}


export const UsersCreationLogsPage = () => {
  const [usersCreationLogs, setUsersCreationLogs] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchUsersCreationLogs()
      .then((data) => {
        setUsersCreationLogs(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <ProgressBar intent={Intent.PRIMARY}/>
    )
  }

  if (!usersCreationLogs.length) {
    return (
      <NonIdealState
        icon={IconNames.LIST}
        title="Нет информации"
      />
    )
  }

  return (
    <Table
      rows={usersCreationLogs}
    />
  )
}
