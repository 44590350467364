import React from 'react'

import { DatePicker as BPDatePicker } from "@blueprintjs/datetime";
import MomentLocaleUtils from "react-day-picker/moment";
import { Classes, Dialog } from "@blueprintjs/core";
import 'moment/locale/ru';

export const DatePicker = ({
  date,
  setDate,
  handleClose
}) => {

  return (
    <Dialog
      isOpen={true}
      onClose={handleClose}
      title="Выбор дня"
      style={{width: 'auto'}}
    >
      <div className={Classes.DIALOG_BODY}>
        <BPDatePicker
          locale='ru'
          defaultValue={new Date(date)}
          localeUtils={MomentLocaleUtils}
          className={Classes.ELEVATION_1}
          minDate={new Date('07-18-2023')}
          maxDate={new Date()}
          onChange={(date) => {
            const dayWithoutTimezone = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
            setDate(dayWithoutTimezone)
            handleClose()
          }}
          highlightCurrentDay
        />
      </div>
    </Dialog>
  )
}