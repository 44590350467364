import React from 'react'
import { ButtonGroup, Button, Intent } from '@blueprintjs/core'


export const Pagination = ({ total, currentPage, pageSize, onChangePage }) => {
  const lastPage = Math.ceil(total / pageSize) - 1
  const backDisabled = currentPage === 0
  const showFirstPage = currentPage > 2
  const showLastPage = currentPage < lastPage - 2
  if (lastPage === 0) return null;
  return (
    <ButtonGroup>
      <Button disabled={backDisabled} onClick={() => onChangePage(currentPage - 1)}>Назад</Button>
      {showFirstPage && (
        <React.Fragment>
          <Button onClick={() => onChangePage(0)}>1</Button>
          {currentPage > 3 && <Button disabled>...</Button>}
        </React.Fragment>
      )}
      {currentPage > 1 && <Button onClick={() => onChangePage(currentPage - 2)}>{currentPage - 1}</Button>}
      {currentPage > 0 && <Button onClick={() => onChangePage(currentPage - 1)}>{currentPage}</Button>}
      <Button active intent={Intent.PRIMARY} disabled>{currentPage + 1}</Button>
      {currentPage < lastPage && <Button onClick={() => onChangePage(currentPage + 1)}>{currentPage + 2}</Button>}
      {currentPage + 1 < lastPage && <Button onClick={() => onChangePage(currentPage + 2)}>{currentPage + 3}</Button>}
      {showLastPage && (
        <React.Fragment>
          {currentPage < lastPage - 3 && <Button disabled>...</Button>}
          <Button onClick={() => onChangePage(lastPage)}>{lastPage + 1}</Button>
        </React.Fragment>
      )}
      <Button disabled={currentPage === lastPage} onClick={() => onChangePage(currentPage + 1)}>Вперед</Button>
    </ButtonGroup>
  )
}
