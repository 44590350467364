import React, { useState } from 'react'
import {InputGroup, FormGroup, Button, Card} from '@blueprintjs/core'
import * as Sentry from "@sentry/browser";

import { login } from 'api'
import styled from "styled-components"

require('./index.css')

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Wrapper2 = styled.div`
  width: 20%;
`


export const AuthPage = ({
  setIsAuthorized
}) => {
  const [form, setForm] = useState({
    username: '',
    password: '',
    totp: '',
  });
  const [formError, setFormError] = useState(null);

  const onChange = (field, value) => {
    const newValue = value.target.value

    setFormError(null)
    setForm(prevState => {
      return {
        ...prevState,
        [field]: newValue,
      }
    })
  }

  return (
    <Wrapper>
      <Wrapper2>
        <FormGroup
          label="Вход"
          className="login-form"
        >
          <InputGroup
            large={true}
            placeholder="Логин"
            type="text"
            className="login-input"
            onChange={(value) => onChange('username', value)}
            value={form.username}
          />
          <InputGroup
            large={true}
            placeholder="Пароль"
            type="password"
            className="login-input"
            onChange={(value) => onChange('password', value)}
            value={form.password}
          />
          <InputGroup
            large={true}
            placeholder="Код аутентификатора"
            type="text"
            className="login-input"
            onChange={(value) => onChange('totp', value)}
            value={form.totp}
          />
          <Button
            rightIcon="arrow-right"
            intent="success"
            className="login-button"
            onClick={async () => {
              try {
                const { user } = await login(form)
                const { username, role } = user

                Sentry.setContext('user', {
                  username,
                });

                localStorage.setItem('role', role)

                setIsAuthorized(true)
              } catch (err) {
                setFormError(err.message)
              }
            }}
          >
            Войти
          </Button>

          {formError && (
            <Card className="error-card">
              <p>Ошибка: {formError}</p>
            </Card>
          )}
        </FormGroup>
      </Wrapper2>
    </Wrapper>
  )
}

